/* istanbul ignore file */
/* tslint:disable */

export enum TicketStatus {
  ACKNOWLEDGED = 'acknowledged',
  ACKNOWLEDGED_AND_GONE = 'acknowledged_and_gone',
  CLOSED = 'closed',
  EVENT = 'event',
  EVENT_CLOSED = 'event_closed',
  GONE = 'gone',
  OPEN = 'open',
  REOPENED = 'reopened',
  SUPPRESSED = 'suppressed'
}
