/* istanbul ignore file */
/* tslint:disable */

export enum TicketCommentType {
  ACKNOWLEDGMENT = 'acknowledgment',
  ALARM = 'alarm',
  DEVICE_ACKNOWLEDGMENT = 'deviceAcknowledgment',
  EVENT_RECURRENCE = 'eventRecurrence',
  INFORMATION = 'information',
  INVALID = 'invalid',
  NOTICE = 'notice',
  RECURRENCE = 'recurrence',
  RESOLUTION = 'resolution',
  SUPPRESSION = 'suppression',
  WARNING = 'warning'
}
